<template>
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />
    <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Kan du ikke få adgang til din konto?</h2>
    <p class="mt-2 text-center text-sm text-gray-600">
      Var det en fejl?
      <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"> Log ind her </a>
    </p>
  </div>

  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
    <div class="bg-white py-8 px-4 shadow rounded-lg sm:px-1">
      <h2 class="text-lg font-medium text-center text-gray-900">Vælg en af nedenstående</h2>

      <div class="max-w-lg mx-auto">
        <ul role="list" class="mt-5 border-t border-b border-gray-200 divide-y divide-gray-200">
          <li>
            <router-link :to="{ name: 'auth-forgot-password' }">
              <div class="relative group py-4 flex items-start space-x-3">
                <div class="flex-shrink-0">
                  <span class="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-pink-500">
                    <!-- Heroicon name: outline/lock-open -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
                    </svg>
                  </span>
                </div>
                <div class="min-w-0 flex-1">
                  <div class="text-sm font-medium text-gray-900">
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    Jeg har glemt min adgangskode
                  </div>
                  <p class="text-sm text-gray-500">Vælge denne hvis du ønsker at gendanne din adgangskode</p>
                </div>
                <div class="flex-shrink-0 self-center">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'auth-forgot-to-factor-auth' }">
              <div class="relative group py-4 flex items-start space-x-3">
                <div class="flex-shrink-0">
                  <span class="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-purple-500">
                    <!-- Heroicon name: outline/refresh -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                  </span>
                </div>
                <div class="min-w-0 flex-1">
                  <div class="text-sm font-medium text-gray-900">
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    Jeg har problemer med totrinsbekræftelse
                  </div>
                  <p class="text-sm text-gray-500">Vælg denne hvis du har problemer med totrinsbekræftelse</p>
                </div>
                <div class="flex-shrink-0 self-center">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'auth-forgot-email' }">
              <div class="relative group py-4 flex items-start space-x-3">
                <div class="flex-shrink-0">
                  <span class="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-yellow-500">
                    <!-- Heroicon name: outline/mail -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                  </span>
                </div>
                <div class="min-w-0 flex-1">
                  <div class="text-sm font-medium text-gray-900">
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    Jeg har ikke længere adgang til min mail
                  </div>
                  <p class="text-sm text-gray-500">Like a conference all about you that no one will care about.</p>
                </div>
                <div class="flex-shrink-0 self-center">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'auth-forgot-password' }">
              <div class="relative group py-4 flex items-start space-x-3">
                <div class="flex-shrink-0">
                  <span class="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-red-600">
                    <!-- Heroicon name: outline/exclamation -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                  </span>
                </div>
                <div class="min-w-0 flex-1">
                  <div class="text-sm font-medium text-gray-900">
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    Jeg tror eller har været udsat for et hackerangreb
                  </div>
                  <p class="text-sm text-gray-500">Vi tager sikkederheden af jeres data meget seriøst</p>
                </div>
                <div class="flex-shrink-0 self-center">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
        <div class="mt-5 text-center">
          <a href="#" class="text-sm font-medium text-indigo-600 hover:text-indigo-500">Eller log ind her<span aria-hidden="true"> &rarr;</span></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>
